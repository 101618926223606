<template>
  <div class="main-container">
    <app-header />
    <nav-bar />
    <div class="content">
      <overlay-loader :loading="loading"></overlay-loader>
      <v-card>
        <v-card-title class="pt-8 mb-8 mx-8">
          <strong>Users</strong>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" underlined></v-text-field>
        </v-card-title>
        <v-card-text class="p-0">
          <v-data-table :headers="headers" :items="users" :search="search">
            <template v-slot:item.modification="{ item }">
              <span v-if="item.modification == ''">-</span>
              <span v-else>{{ item.modification }}</span>
            </template>
            <template v-slot:item.modification_timestamp="{ item }">
              <span >{{ item.modification_timestamp.replace('T', ' ') }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { accountServices } from '@/services/accountServices';

export default {
  name: 'AuditUsers',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
  },
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        { text: 'User', value: 'user_email' },
        { text: 'Licence', value: 'user_licence' },
        { text: 'Event description', value: 'event_description' },
        { text: 'Action', value: 'action' },
        { text: 'Modification', value: 'modification' },
        { text: 'Modifier', value: 'modifier_email' },
        { text: 'Last mod. timestamp', value: 'modification_timestamp' },
      ],
      users: [],
    };
  },
  created() {},
  mounted() {
    this.getUserHistory();
  },
  computed: {},
  methods: {
    getUserHistory() {
      this.loading = true;
      accountServices.getUserHistory()
        .then((response) => {
          this.loading = false;
          this.users = response.data.map((item) => ({
            ...item, modification: item.modification ? item.modification.replace(',', ', ') : '',
          }));
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
  .doc {
    margin-left:  50px;
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .title_doc {
    margin-top: 20px;
    margin-left: 50px;
  }
</style>
